
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "67933e5e-33f5-483d-9b41-96375857e19e"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/Users/andy/Development/Mallpromo Platform/Client/Client 3.0/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
